export default function PropertyManagement() {
	return (
		<section id="Hausverwaltung" className="w-full bg-sky-50 p-10">
			<section className="flex flex-col items-center">
				<h2 className="text-2xl lg:text-3xl font-thin  text-sky-900">
					Hausverwaltung
				</h2>
				<p className="max-w-[600px] text-slate-500 mt-8 text-sm lg:text-lg font-semibold  text-center">
					Hausverwaltungen gibt es viele. Die richtige haben Sie
					gefunden, wenn Kompetenz, Engagement und Vertrauen stimmen.
					Und wenn wir damit auch Ihre Vorstellungen treffen, freuen
					wir uns auf einen ersten Kontakt.
				</p>
			</section>
			<section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:items-stretch justify-stretch lg:justify-center items-center gap-8 mt-8 w-full">
				<article className="rounded-lg p-4  bg-sky-900 bg-opacity-20">
					<h3 className="text-xl font-bold text-sky-900">
						Kaufmännische Verwaltung
					</h3>
					<p className="mt-4">
						<strong>
							Mietermanagement, Versicherungen, Verträge oder
							Streitfälle -
						</strong>
					</p>
					<blockquote className="mt-4">
						unser Leistungsspektrum umfasst die gewissenhafte
						Wahrnehmung Ihrer Rechte gegenüber allen Behörden
						(einschließlich Grundbuchämtern), bei Hypotheken- und
						Darlehensgläubigern sowie gegenüber Lieferanten,
						Handwerkern, Mietern oder Pächtern.
					</blockquote>

					<blockquote className="mt-4">
						Dazu kommt die Durchführung aller Maßnahmen, die für
						eine ordnungsgemäße Bewirtschaftung erforderlich sind.
					</blockquote>

					<blockquote className="mt-4">
						Immobilien haben ein durchaus bewegtes Leben. Nicht nur
						ihre Mieter oder Eigentümer wechseln. Oft werden sie im
						Laufe der Jahre auch ganz unterschiedlich genutzt. Ob
						bei der Ausführung behördlicher Auflagen oder bei der
						Suche nach dem optimalen Mietermix.
					</blockquote>
				</article>
				<article className=" rounded-lg p-4  bg-sky-900 bg-opacity-20">
					<h3 className="text-xl font-bold text-sky-900">
						Technische Verwaltung
					</h3>
					<p className="mt-4">
						<strong>Haustechnik ist ein sensibles Thema - </strong>
					</p>
					<blockquote className="mt-4">
						vor allem im Fall des Ausfalls, besonders nachts oder am
						Wochenende. Gut zu wissen, dass unser Team für Sie
						„betreuungs-technisch“ versiert ist. In jeder Hinsicht
						und zu jedem Zeitpunkt: vom Veranlassen der
						Sofortmaßnahmen in Fällen wie Rohrbruch, Brand- oder
						Sturmschäden bis zu den Schadensmeldungen an die
						Versicherung.
					</blockquote>
					<blockquote className="mt-4">
						Dazu übernehmen wir das Einholen von Angeboten und die
						Erteilung von Aufträgen für Reparaturen oder
						Instandsetzungsmaßnahmen innerhalb eines vertraglich
						vereinbarten finanziellen Rahmens.
					</blockquote>
					<blockquote className="mt-4">
						Selbstverständlich kümmern wir uns auch um die
						pünktliche Wartung der Anlagen und sorgen für die
						Umsetzung der gesetzlichen Vorschriften.
					</blockquote>
				</article>
				<article className="rounded-lg p-4  bg-sky-900 bg-opacity-20 ">
					<h3 className="text-xl font-bold text-sky-900">
						Serviceleistungen
					</h3>
					<p className="mt-4">
						<strong>Neuvermietung -</strong>{' '}
					</p>
					<blockquote className="mt-4">
						hier übernehmen wir nach Vereinbarung das komplette
						Procedere für Sie: von der Werbung (Inserate, Internet,
						Flyer) über die Vorauswahl der Interessenten bis hin zur
						Begehung im Objekt und dem Abschluss sämtlicher
						Verträge.
					</blockquote>
					<p className="mt-8">
						<strong>Betreuung Modernisierungsmaßnahmen</strong>{' '}
					</p>
					<p className="mt-4 text-pretty">
						<li className="p-2 text-sky-800 font-light text-center text-pretty">
							Begehung der Wohnung Vorbereitung der Mieter in
							Einzelgesprächen
						</li>
						<li className="p-2 text-sky-800 font-light text-center text-pretty">
							Ausfertigung sämtlicher Ankündigungen inkl.
							Berechnung der Mieterhöhungen
						</li>
						<li className="p-2 text-sky-800 font-light text-center">
							Bekanntgabe der verbindlichen Mieterhöhungen nach
							Abschluss aller Arbeiten
						</li>
					</p>
					<p className="mt-4">
						<strong className="text-sky-900 text-center">
							Sprechen Sie uns einfach an.
						</strong>
					</p>
				</article>
			</section>
			<section className="flex flex-col items-center mt-16">
				<article className=" rounded-lg p-4 w-full lg:px-28 text-center flex items-center flex-col bg-sky-950 ">
					<h2 className="text-2xl lg:text-3xl text-center font-thin  text-sky-100">
						Mieterinformation
					</h2>
					<p className="max-w-[600px] text-sky-50 mt-8 text-sm lg:text-lg opacity-90 ">
						Sie interesserien sich für diese Wohnung? Dann laden Sie
						jetzt den Mieterbewerbungsbogen herunter und bewerben
						Sie sich in nur wenigen Schritten!
					</p>

					<p className="max-w-[600px] text-amber-200 mt-8 text-sm lg:text-lg uppercase hover:cursor-pointer hover:text-sky-50">
						<a
							href="downloads/Bewerbungsbogen.pdf"
							download="Horizont-Bewerbungsbogen.pdf"
						>
							<strong>Download Mieter-Bewerbungsbogen</strong>
						</a>
					</p>
				</article>
			</section>
		</section>
	);
}
