import React, { useEffect, useState } from 'react';
import EstateObject from './EstateObject';
import GetServerData from '../lib/getServerData';

function Offers({ openModal }) {
	const [objects, setObjects] = useState([]);

	useEffect(() => {
		async function GetData() {
			try {
				const response = await GetServerData();
				console.log(response);
				setObjects(response);
			} catch (error) {
				console.error('Fehler beim Abrufen der Daten');
			}
		}
		GetData();
	}, []);

	return (
		<>
			<div
				id="Immobilienangebote"
				className=" flex justify-center flex-col pt-8 bg-sky-100"
			>
				<header className=" text-2xl lg:text-3xl text-center font-thin text-sky-950 mb-8">
					Ihre Immobilie in guten Händen - Hier finden Sie unsere
					Angebote
				</header>

				<section
					id="Immobilienangebote"
					className=" grid grid-cols-1 md:grid-cols-2 gap-6 xl:grid-cols-3 px-8 pb-8 justify-center bg-sky-100"
				>
					{objects.map((object) => (
						<EstateObject
							objectID={object.objectID}
							openModal={openModal}
							key={object.id}
							title={object.title}
							location={object.location}
							description={object.description}
							size={object.size}
							units={object.units}
							land={object.land}
							available={object.available}
							status={object.status}
							price={object.price}
							image={object.imageUrl}
							avalability={object.availability}
						/>
					))}
				</section>
			</div>
		</>
	);
}

export default Offers;
