export default function HomeStaging() {
	return (
		<section className="w-full bg-sky-50 p-4">
			<article className="flex justify-center items-center flex-col text-pretty mt-8 rounded-t-xl bg-gradient-to-b shadow-lg from-bg-sky-300 to-bg-sky-950 p-4 text-lg text-white">
				<h2 className="text-2xl lg:text-3xl text-center  font-thin mb-8 uppercase text-sky-50 ">
					Home Staging & Home Design
				</h2>
				<p className="my-4 text-center max-w-4xl text-xl lg:text-2xl text-balance">
					Nur verkaufen kann jeder - wir denken Ihre Immobilie neu.
				</p>
				<p className="my-4 text-center max-w-4xl text-sm lg:text-lg text-balance">
					Durch geschicktes umstylen oder das Finden unentdeckter
					Reserven lässt sich der Verkaufswert optimieren.
				</p>
				<p className="my-4 text-center max-w-4xl text-sm lg:text-lg text-balance">
					Von der Designer-Lampe bis zum Gartenzwerg. Unsere
					Home-Staging Experten haben sicherlich die zündende Idee zum
					schöner Wohnen oder besser Verkaufen.
				</p>

				<a
					href="/#Kontakt"
					className="mt-8 text-center  rounded-lg px-4 py-2 bg-orange-300 text-gray-800  lg:hover:scale-110 lg:hover:shadow-2xl transition-transform ease-in-out shadow-lg max-w-4xl text-sm lg:text-lg text-balance"
				>
					<strong> Sprechen Sie uns an!</strong>
				</a>
			</article>
			<article className="bg-sky-950  w-full rounded-b-lg max-h-[600px] px-8 pb-8 md:pb-16 overflow-hidden ">
				<section className="max-w-[80%] h-full m-auto bg-green flex flex-row md:flex:col py-8 ">
					<div className="object-cover ">
						<img
							className="rounded-3xl  grayscale hover:grayscale-0   object-contain  shadow-2xl hover:shadow-lg hover:shadow-black-800 hover:scale-110 transition-all ease-in-out "
							src="images/floorplanpencils.jpeg"
							alt="Floorplan"
						></img>
					</div>
					<div className="object-cover max-h-full">
						<img
							className="rounded-3xl grayscale hover:grayscale-0    shadow-2xl hover:shadow-lg hover:shadow-black-800 hover:scale-110 transition-all ease-in-out "
							src="images/floorplan.jpeg"
							alt="Floorplan"
						></img>
					</div>
				</section>
			</article>
		</section>
	);
}
