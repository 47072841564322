import ContactForm from './ContactForm';
import {
	HouseSmileIcon,
	MailIcon,
	PhoneIcon,
	MobileIcon,
	FaxIcon
} from './Icons';

export default function Contact() {
	return (
		<section id="Kontakt" className="w-full bg-sky-900 p-8">
			<header className=" text-2xl lg:text-3xl text-center font-thin text-sky-50 mb-8">
				Kontakt
			</header>

			<section className=" grid lg:grid-cols-2 gap-8  ">
				<article className="shadow-lg shadow-sky-950  bg-sky-200  outline-sky-600 bg-opacity-30 outline-1 rounded-2xl p-4">
					<ContactForm />
				</article>
				<article className="w-full flex justify-center items-center ">
					<section className="py-8 ">
						<div className="flex flex-row gap-4 font-bold text-sky-200 text-lg lg:text-xl my-8">
							<div>
								<HouseSmileIcon
									color={'rgb(186,230,253)'}
									size={50}
									stroke={1}
								/>
							</div>
							<div className="text-sm lg:text-lg">
								<p className="">Horizont Consulting GmbH</p>
								<p className="">Birkfeldstr. 1</p>
								<p className="">16562 Bergfelde</p>
							</div>
						</div>

						<div className="flex flex-col my-8 gap-y-2 ">
							<div className="flex flex-row gap-6 items-center text-sm lg:text-lg font-bold text-sky-200 ">
								<div>
									{' '}
									<PhoneIcon
										color={'rgb(186,230,253)'}
										size={50}
										stroke={1}
									/>
								</div>
								<p className="">03303 214 551</p>
							</div>
							<div className="flex flex-row gap-6 items-center text-sm lg:text-lg font-bold text-sky-200 ">
								<div>
									<FaxIcon
										color={'rgb(186,230,253)'}
										size={50}
										stroke={1}
									/>
								</div>
								<p className="">03303 214 988</p>
							</div>
							<div className="flex flex-row gap-6 items-center text-sm lg:text-lg font-bold text-sky-200">
								<div>
									{' '}
									<MobileIcon
										color={'rgb(186,230,253)'}
										size={50}
										stroke={1}
									/>
								</div>
								<p className="">01624 251 904</p>
							</div>
							<div className="flex flex-row gap-6 font-bold items-center text-sky-200 ">
								<div>
									<MailIcon
										color={'rgb(186,230,253)'}
										size={50}
										stroke={1}
									/>
								</div>
								<p className="text-sm lg:text-lg">
									<a href="mailto:buero@horizont-service.de">
										buero@horizont-service.de
									</a>
								</p>
							</div>
						</div>
					</section>
				</article>
			</section>
		</section>
	);
}
