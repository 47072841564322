import { Link } from 'wouter';
import { HamburgerIcon } from './Icons';
import Logo from './Logo';

export default function Navbar({ toggleNavbar, NavbarOpen }) {
	const handleClick = () => toggleNavbar();

	return (
		<div className=" sticky z-[9999] top-0 ">
			<div
				className="bg-white text-[rgba(60,72,118,0.77)] font-bold flex justify-between  items-center 
		   rounded-t-lg m-auto w-full px-4 lg:px-14 py-2"
			>
				<div className="flex justify-between w-full items-center  ">
					<div className="flex flex-row  justify-center  items-center">
						<Logo size={3} />
						<div className="uppercase opacity-75 ml-4  text-nowrap text-sm lg:text-2xl text-slate-800  ">
							<a href="/#">
								Horizont{' '}
								<span className="font-thin">Consulting</span>
							</a>
						</div>
					</div>
					<div
						onClick={handleClick}
						className="h-14 lg:hidden w-full flex justify-end items-center  cursor-pointer"
						// className={`${NavbarOpen ? 'h-24' : 'w-48'}`}
					>
						<HamburgerIcon color="rgba(60,72,118,0.77)" size={50} />
					</div>
					<nav className=" flex flex-col lg:relative lg:right-auto  z-[99999] ">
						<div
							// ${NavbarOpen ? 'w-56' : 'w-0 '}
							//TODO REMOVE UNNEEDED CODE ON
							className={`hidden text-xl lg:flex-row  lg:py-0 lg:px-0 gap-8 lg:flex w-full   lg:rounded-none lg:duration-300 bg-white lg:top-auto lg:right-auto lg:relative `}
						>
							<a
								href="/#"
								className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
							>
								Wir
							</a>
							<a
								href="/#services "
								className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
							>
								Leistungen
							</a>

							<a
								href="/#Hausverwaltung "
								className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
							>
								Verwaltung
							</a>
							<a
								href="/#Immobilienangebote "
								className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
							>
								Immobilien
							</a>
							<a
								href="/#Kontakt"
								className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
							>
								Kontakt
							</a>
						</div>
					</nav>
				</div>
			</div>
			{NavbarOpen ? (
				<nav className="lg:hidden flex flex-col bg-white gap-8 py-8 rounded-b-lg text-xl absolute w-full z-[99999]">
					<a
						href="/#"
						className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
					>
						Wir
					</a>
					<a
						href="/#services "
						className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
					>
						Leistungen
					</a>
					<a
						href="/#Hausverwaltung "
						className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
					>
						Verwaltung
					</a>
					<a
						href="/#Immobilienangebote "
						className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
					>
						Immobilien
					</a>
					<a
						href="/#Kontakt"
						className=" hover:scale-y-110 cursor-pointer hover:text-amber-600   ease-in-out text-center"
					>
						Kontakt
					</a>
				</nav>
			) : (
				''
			)}
		</div>
	);
}
