export default function Impressum() {
	return (
		<section className="bg-sky-50 p-8">
			<h5 className="text-bold text-xl text-sky-500 mb-4">Impressum</h5>

			<h2 className="my-4 font-bold text-sky-900">
				Angaben gem&auml;&szlig; &sect; 5 TMG
			</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				Horizont Consulting GmbH
				<br />
				Birkfeldstra&szlig;e 1
				<br />
				<br />
				16562 Bergfelde
				<br />
				<br />
			</p>

			<p className="mt-2 text-pretty font-light text-sky-700">
				<strong>Handelsregister: </strong>
				<br />
				HRB 10794
				<br />
				<br />
				<strong>Registergericht:</strong>
				<br />
				Amtsgericht Neuruppin
			</p>

			<p className=" text-pretty font-light text-sky-700 mt-4">
				<strong>Vertreten durch:</strong>
				<br />
				Julian Toutenburg
			</p>

			<h2 className="my-4 font-bold text-sky-900">Kontakt</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				Telefon: +49 (0) 3303 214 551
				<br />
				Telefax: +49 (0) 3303 214 988
				<br />
				E-Mail: buero@horizont-service.de
			</p>

			<h2 className="my-4 font-bold text-sky-900">Umsatzsteuer-ID</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				Amtsgericht Neuruppin HRB 10794
				<br />
				NP Steuernummer 05311003986
				<br />
				Geschäftsführer Julian und Christoph Toutenburg
			</p>

			<h2 className="my-4 font-bold text-sky-900">
				Aufsichtsbeh&ouml;rde
			</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				<strong>
					Erlaubnis gemäß §34c Gewerbeordnung -GewO- erteilt durch:
				</strong>{' '}
				<br />
				Stadt Hohen Neuendorf, Gewerbeamt (Ordnungsamt)
				<br />
				<br />
				<strong>Berufsaufsichtsbehörde gemäß §34c -GewO-:</strong>
				<br />
				Stadt Hohen Neuendorf, Gewerbeamt (Ordnungsamt), Oranienburger
				Str. 2, 16540 Hohen Neuendorf
			</p>

			<h2 className="my-4 font-bold text-sky-900">
				berufsrechtliche Regelungen
			</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				<strong>
					Informationen nach § 11 Abs. 1
					Versicherungsvermittlerverordnung (VersVermV):
				</strong>{' '}
				<br />
				<strong>Status/Stellung:</strong>
				<br />
				Versicherungsvertreter mit Erlaubnis nach § 34d Abs. GewO
				<br />
				<strong>Registriernummer:</strong>
				<br />
				D-5FK0-NYK6Y-38
				<br />
				<br />
				<strong>Registrierstelle:</strong>
				<br />
				Deutscher Industrie- und Handelskammertag (DIHK) e.V., Breite
				Str. 29, 10178 Berlin, Tel.: 0180 500585
				<br />
				Es bestehen keine Beteiligungen an und von
				Versicherungsgesellschaften
				<br />
				<br />
				<strong>Schlichtungsstellen:</strong>
				<br />
				Versicherungsombudsmann e.V., Psf.: 080632, 10006 Berlin, Tel.:
				01804 224424
			</p>

			<h2 className="my-4 font-bold text-sky-900">
				EU-Streitschlichtung
			</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				Die Europ&auml;ische Kommission stellt eine Plattform zur
				Online-Streitbeilegung (OS) bereit:{' '}
				<a
					href="https://ec.europa.eu/consumers/odr/"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://ec.europa.eu/consumers/odr/
				</a>
				.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
			</p>

			<h2 className="my-4 font-bold text-sky-900">
				Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
			</h2>
			<p className="mt-2 text-pretty font-light text-sky-700">
				Wir sind nicht bereit oder verpflichtet, an
				Streitbeilegungsverfahren vor einer
				Verbraucherschlichtungsstelle teilzunehmen.
			</p>
		</section>
	);
}
