export default function Hero() {
	return (
		<div
			className="relative overflow-hidden  bg-cover bg-no-repeat   text-center"
			style={{ backgroundImage: 'url(images/smaller.jpeg)' }}
		>
			<div className="  flex flex-col lg:flex-row gap-4 lg:gap-10 rounded-lg lg:m-10 h-auto m-4">
				<article className="inline-block  ">
					<div className="bg-amber-600 inline-block mb-4">
						<h1 className="text-2xl md:text-4xl font-light uppercase text-slate-800 p-2">
							Immobilienlösungen mit Mehrwert
						</h1>
					</div>
					<div className=" text-xl md:text-4xl font-serif w-auto  justify-center flex mx-auto flex-row ">
						<p className=" text-slate-600">Immobilien</p>
						<p className="text-amber-600 ">Verwaltung</p>
						<p className="text-slate-600">Versicherung</p>
					</div>
				</article>
				<article className="mx-2  text-sm lg:text-xl   text-white text-balance  p-8 bg-sky-950 bg-opacity-50 backdrop-blur-lg flex-col  rounded-lg ">
					<p className="md:text-center  break-words    break-keep p-8 backdrop-blur-2xl">
						<strong className="  font-thin text-2xl lg:text-3xl">
							Eigentum verpflichtet.
						</strong>
					</p>
					<p className="my-2 text-slate-300">
						Die{' '}
						<strong className="uppercase text-lg text-white lg:text-2xl opacity-80  ">
							Horizont Consulting GmbH
						</strong>{' '}
						ist seit 2009 am Immobilienmarkt für Sie tätig.
					</p>
					<p className="my-4 text-slate-300">
						Ob Immobilienverkauf, Verwaltung oder die Beratung in
						Versicherungsangelegenheiten - bei uns finden sie einen
						engagierten und kompetenten Ansprechpartner.
					</p>

					<p className="my-4 text-orange-300 text-sm lg:text-xl py-2 rounded-lg font-semibold backdrop-blur-2xl bg-sky-950 bg-opacity-35 text-balance">
						Wir freuen uns auf Sie!
					</p>
				</article>
			</div>
		</div>
	);
}
