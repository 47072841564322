import { useState } from 'react';
import sendMail from '../lib/sendMail';

// TODO SHOW ERROR MESSAGE ON SUBMIT

export default function ContactForm() {
	const [error, setError] = useState('');
	const [mailSending, setMailSending] = useState(false);
	const [mailSuccess, setMailSuccess] = useState(false);
	const [disableMail, setDisableMail] = useState(false);
	const [mailStatus, setMailStatus] = useState('Formular absenden');

	// TODO SHOW RETURN SUCCESS AND ERROR MESSAGE ON SUBMIT
	// TODO EMPTY INPUT FIELDS

	async function handleSubmit(e) {
		setError('');
		setMailSending(true);
		setMailStatus('Formular wird gesendet...');
		setDisableMail(true);
		e.preventDefault();

		const formData = new FormData(e.target);
		const formDataJson = Object.fromEntries(formData.entries());

		const sendToOffice = await sendMail(formDataJson);

		if (sendToOffice == 'sended') {
			setMailStatus('Formular wurde erfolgreich versendet.');
			e.target.reset();
		} else {
			setError(sendToOffice);
			setMailStatus('Fehler beim Absenden der Mail. :-( ');
			console.log(sendToOffice);
		}
	}
	return (
		<div className=" p-4 ">
			<div className="rounded-lg shadow text-sm lg:text-lg">
				<form
					onSubmit={(e) => handleSubmit(e)}
					className="space-y-8"
					action="#"
				>
					<div>
						<input
							type="email"
							name="email"
							id="email"
							className=" border font-bold  rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-sky-50 border-sky-700 placeholder-gray-400 text-sky-950"
							placeholder="name@immobilienbesitzer.com"
							required
						></input>
					</div>
					<div>
						<input
							type="name"
							name="name"
							id="name"
							placeholder="Max Mustermann"
							className="border font-bold rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-sky-50 border-sky-700 placeholder-gray-400 text-sky-950"
							required
						></input>
					</div>
					<div>
						<input
							type="phone"
							name="phone"
							id="phone"
							placeholder="0172 345 678"
							className="border font-bold rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-sky-50 border-sky-700 placeholder-gray-400 text-sky-950"
							required
						></input>
					</div>
					<div className="">
						<textarea
							type="message"
							name="message"
							id="message"
							rows="5"
							placeholder="Ihre Anfrage"
							className="border font-bold rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-sky-50 border-sky-700 placeholder-gray-400 text-sky-950"
							required
						></textarea>
					</div>
					<div>
						<button
							type="submit"
							disabled={disableMail}
							className={`w-full  mt-5  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
								disableMail
									? 'bg-sky-50 text-sky-950'
									: 'bg-sky-600 text-sky-50 hover:bg-sky-700'
							}  focus:ring-sky-800`}
						>
							{mailStatus}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
