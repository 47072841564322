import * as React from 'react';
export default function Logo() {
	return (
		<div
			className={`size-[50px] p-1 shadow-lg bg-blue-100 flex justify-center items-center  rounded-full`}
		>
			<img src="images/logo.png" alt="Horizont-Consulting-Logo"></img>
		</div>
	);
}
