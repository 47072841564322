import Stars from './Stars';

export default function EstateObject({
	id,
	objectID,
	title,
	location,
	description,
	size,
	units,
	land,
	available,
	status,
	price,
	image,
	avalability,
	openModal
}) {
	return (
		<div
			onClick={() => {
				if (available) {
					openModal({
						id,
						objectID,
						title,
						location,
						description,
						size,
						units,
						land,
						available,
						status,
						price,
						image
					});
				}
			}}
			className="w-full  border border-gray-200 rounded-xl  bg-gray-700 relative shadow-xl shadow-slate-800 justify-between min-w-[380px]flex-col "
		>
			<div
				className={` ${
					available
						? 'bg-green-500  hover:bg-green-700 hover:w-72  duration-500 ease-in-out'
						: 'bg-red-500 hover:bg-red-700 hover:w-72 duration-500 ease-in-out'
				} absolute right-0 top-4 w-48 h-16 text-white hover:font-bold focus:ring-4 flex justify-center items-center  font-medium rounded-l-xl text-m px-5 py-2.5 text-center`}
			>
				{status}
			</div>

			<img
				className="w-full rounded-t-xl object-cover h-[200px] object-center"
				src={image}
				alt={title}
			/>
			<section className="px-5  flex flex-col items-stretch ">
				<header className="flex flex-col justify-start flex-grow py-4">
					<h5 className="text-sm lg:text-lg  font-semibold tracking-tight text-white">
						{title}
					</h5>
					<p className="text-sm lg:text-lg tracking-tight text-white opacity-45">
						<strong>Objekt-ID: </strong>
						{objectID}
					</p>
					<p className="text-sm lg:text-lg   tracking-tight text-white opacity-45">
						<strong>Lage: </strong>
						{location}
					</p>
					<h4 className="text-sm lg:text-lg pt-4 font-light  text-slate-300">
						{description}
					</h4>
				</header>
				<article className="flex flex-col mb-4">
					<section className="flex flex-col text-white text-sm lg:text-lg mb-4">
						<article className="flex flex-row gap-x-4 justify-between">
							<p>Fläche:</p>
							<p>{size}</p>
						</article>
						<article className="flex flex-row gap-x-4 justify-between">
							<p>Einheiten:</p>
							<p> {units}</p>
						</article>
						<article className="flex flex-row gap-x-4 justify-between">
							<p>Grundstücksgröße:</p>
							<p>{land}</p>
						</article>
						<article className="flex flex-row gap-x-4 justify-between">
							<p>Verfügbarkeit:</p>
							<p>{avalability}</p>
						</article>
					</section>

					<Stars />
					<div className="flex justify-end mt-2.5 ">
						<span className="text-2xl font-bold  text-slate-400">
							{price} €
						</span>
					</div>
				</article>
			</section>
			<div className="flex justify-center mb-4 ">
				<button
					onClick={() =>
						openModal({
							id,
							title,
							location,
							description,
							size,
							units,
							land,
							available,
							status,
							price,
							image
						})
					}
					disabled={!available}
					className={` ${
						available
							? 'bg-sky-700  hover:bg-sky-800'
							: 'bg-grey-700'
					} text-white   focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
				`}
				>
					{available ? 'Exposé anfordern' : 'bereits verkauft'}
				</button>
			</div>
		</div>
	);
	// <img src="https://picsum.photos/seed/picsum/500/500" />
}
