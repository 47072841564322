import { InsuranceIcon, PropertyIcon, RealEstateIcon } from './Icons';

export default function Services() {
	return (
		<div id="services" className="w-full h-full bg-blue-950 p-8">
			<div className="flex flex-col md:flex-row gap-8 justify-evenly items-center md:items-start h-full">
				<article className="max-w-[300px] flex flex-col  gap-8">
					<section className="flex flex-col items-center  ">
						<RealEstateIcon
							size={100}
							color={'rgb(148, 163, 184)'}
							stroke={0.8}
						/>

						<div className="inline-block border-b-2 border-dotted w-full border-slate-400 mt-4"></div>
						<h2 className="text-slate-400 text-2xl my-4 font-light">
							Immobilienverkauf
						</h2>
						<div className="inline-block border-b-2 border-dotted w-full border-slate-400"></div>
					</section>

					<section className="flex flex-col gap-y-8">
						<p className="text-sky-100 text-center text-pretty">
							Haus zu verkaufen? Wir finden den solventen Käufer.
							Und für Sie das passende Objekt.
						</p>
						<a
							href="/#Immobilienangebote"
							className="text-sky-100 text-center text-pretty hover:underline"
						>
							Weiter zu unseren Verkaufsobjekten
						</a>
					</section>
				</article>

				<article className="max-w-[300px] flex flex-col gap-8">
					<section className="flex flex-col items-center  ">
						<PropertyIcon
							size={100}
							color={'rgb(148, 163, 184)'}
							stroke={0.8}
						/>
						<div className="inline-block border-b-2 border-dotted w-full border-slate-400 mt-4"></div>
						<h2 className="text-slate-400 text-2xl my-4 font-light">
							Hausverwaltung
						</h2>
						<div className="inline-block border-b-2 border-dotted w-full border-slate-400"></div>
					</section>
					<section className="flex flex-col gap-y-8">
						<p className="text-sky-100 text-center text-pretty">
							Objektbetreuung gesucht? Nutzen Sie unser Know-How
							in der privaten und gewerblichen Hausverwaltung.
						</p>
						<a
							href="/#Hausverwaltung"
							className="text-sky-100 text-center text-pretty hover:underline"
						>
							Weiter zu unseren Eigentümer-/Mieterinformationen
						</a>
					</section>
				</article>

				<article className="max-w-[300px] flex flex-col gap-8">
					<section className="flex flex-col items-center ">
						<InsuranceIcon
							size={100}
							color={'rgb(148, 163, 184)'}
							stroke={0.8}
						/>
						<div className="inline-block border-b-2 border-dotted w-full border-slate-400 mt-4"></div>
						<h2 className="text-slate-400 text-2xl my-4 font-light">
							Versicherungen
						</h2>
						<div className="inline-block border-b-2 border-dotted w-full  border-slate-400"></div>
					</section>

					<section className="flex flex-col gap-y-8">
						<p className="text-sky-100 text-center text-pretty">
							Sie suchen eine Immobilienversicherung? Oder haben
							Ärger mit der Schadenregulierung? Wir vermitteln den
							richtigen Partner.
						</p>

						<a
							href="/#Versicherung"
							className="text-sky-100 text-center text-pretty hover:underline"
						>
							Weiter zu unseren Versicherungsexperten
						</a>
					</section>
				</article>
			</div>
		</div>
	);
}
