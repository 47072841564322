import Hero from './Hero';
import Offers from './Offers';
import PropertyManagement from './PropertyManagement';
import Services from './Services';
import ContactObjectModal from './ContactObjectModal';
import Welcome from './Welcome';
import Contact from './Contact';
import HomeStaging from './HomeStageing';
import Insurance from './Insurance';

export default function Main({
	openModal,
	closeModal,
	NavbarOpen,
	isOpen,
	modalProps
}) {
	return (
		<>
			<Hero />
			<Welcome />
			<Services />
			<PropertyManagement />
			<div>
				{isOpen && (
					<ContactObjectModal
						closeModal={closeModal}
						modalProps={modalProps}
					/>
				)}
			</div>
			<HomeStaging />
			<Insurance />
			<Offers openModal={openModal} />
			<Contact />
		</>
	);
}
