// TODO SHOW RETURN SUCCESS AND ERROR MESSAGE ON SUBMIT

export default async function sendMail(formDataJson) {
	try {
		const response = await fetch(
			'https://api.detailblick.com/V1/horizont/mailer/',

			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formDataJson)
			}
		);
		const json = await response.json();
		return 'sended';
	} catch (error) {
		return error;
	}
}
