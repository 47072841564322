// export default async function GetServerData() {
// 	try {
// 		//const response = await fetch('data/objects.json');
// 		const response = await fetch(
// 			'https://api.detailblick.com/V1/horizont/mailer/objects'
// 		);
// 		const data = await response.json();

// 		return data;
// 	} catch (error) {
// 		console.error('Fehler beim Abrufen der Daten:', error);
// 		throw error;
// 	}
// }

//https://gwtpuadaausovozpznuy.supabase.co/rest/v1/offers?select=*

export default async function GetServerData() {
	try {
		//const response = await fetch('data/objects.json');
		const response = await fetch(
			'https://gwtpuadaausovozpznuy.supabase.co/rest/v1/offers?select=*',
			{
				method: 'GET',
				headers: {
					Apikey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd3dHB1YWRhYXVzb3ZvenB6bnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ0ODA0NzIsImV4cCI6MjAyMDA1NjQ3Mn0.WmguP0tDs4kR0XPISlKLyjZz3CCQFwISwi2l1oszLak',
					Authorization:
						'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd3dHB1YWRhYXVzb3ZvenB6bnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ0ODA0NzIsImV4cCI6MjAyMDA1NjQ3Mn0.WmguP0tDs4kR0XPISlKLyjZz3CCQFwISwi2l1oszLak'
				}
			}
		);
		const data = await response.json();
		console.log(data);

		return data;
	} catch (error) {
		console.error('Fehler beim Abrufen der Daten:', error);
		// throw error;
	}
}
