import { HouseSmileIcon, PhoneIcon } from './Icons';

export default function Footer() {
	return (
		<footer className=" bg-sky-950">
			<div className="mx-auto w-full max-w-screen-xl p-4 flex justify-between ">
				<article className="text-sky-50 inline-block p-4 rounded-lg">
					<div className="text-sm">
						<p className="mb-4 font-bold mt-2">
							Horizont Consulting GmbH
						</p>
						<div className="flex flex-row gap-4">
							<HouseSmileIcon
								color="white"
								size={20}
								stroke={1}
							/>
							{/* <img
								src="icons/house-smile.svg"
								className="h-4"
								alt="Adresse"
							></img> */}
							<div>
								<p> Birkfeldstrasse 1</p>
								<p>16562 Bergfelde</p>
							</div>
						</div>

						<div className="flex flex-row gap-4 mt-2">
							<PhoneIcon color="white" size={20} stroke={1} />

							<div>
								<p>01624 251 904</p>
								<p>03303 214 551</p>
							</div>
						</div>
					</div>
				</article>
				<article className="text-sky-50 flex p-4 rounded-lg ">
					<div className="flex flex-col  justify-end text-sm mt-2 text-right">
						<a
							className="hover:cursor-pointer hover:text-sky-600"
							href="/impressum"
						>
							Impressum
						</a>
						<a
							className="hover:cursor-pointer hover:text-sky-600"
							href="/datenschutz"
						>
							Datenschutz
						</a>
					</div>
				</article>
			</div>
		</footer>
	);
}
