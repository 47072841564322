import { useState } from 'react';
import sendMail from '../lib/sendMail';

// TODO SHOW ERROR MESSAGE ON SUBMIT

export default function ContactObjectModal({ closeModal, modalProps }) {
	console.log(modalProps);
	const { title, description, objectID, location } = modalProps;
	const [error, setError] = useState('');

	async function handleSubmit(e) {
		e.preventDefault();
		setError('');

		const formData = new FormData(e.target);
		const formDataJson = Object.fromEntries(formData.entries());
		formDataJson.title = title;
		formDataJson.description = description;
		formDataJson.objectID = objectID;
		formDataJson.location = location;

		const sendToOffice = await sendMail(formDataJson);
		if (sendToOffice == 'sended') {
			closeModal();
		} else setError(sendToOffice);
	}
	return (
		<div
			id="contact-object-modal"
			tabindex="-1"
			aria-hidden="true"
			className=" overflow-y-auto z-[999999] overflow-x-hidden fixed top-0 right-0 left-0  flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-lg"
		>
			<div className="absolute p-4 w-full max-w-md z-100 max-h-full">
				<div className=" rounded-lg shadow relative bg-gray-700 z-60">
					<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
						<h3 className="text-xl font-semibold text-white">
							Exposé Anfrage
						</h3>
						<button
							type="button"
							className="end-2.5 text-gray-400 bg-transparent   rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
							data-modal-hide="authentication-modal"
							onClick={closeModal}
						>
							<svg
								className="w-3 h-3"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 14 14"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
								/>
							</svg>
							<span className="sr-only">Close modal</span>
						</button>
					</div>

					<div className="px-4 mt-4 md:px-5 rounded-lg font-light text-sm md:text-lg  text-gray-200">
						<strong>Object-ID: </strong>
						{objectID}
					</div>
					<div className="px-4 mt-2 md:px-5 rounded-lg font-light text-sm md:text-lg  text-gray-200">
						<strong>Lage: </strong>
						{location}
					</div>

					<p className="px-4 mt-4 md:px-5 rounded-lg font-light text-sm md:text-lg text-pretty text-gray-200">
						{description}
					</p>

					<div className="p-4 md:p-5 rounded-lg shadow">
						<form
							onSubmit={(e) => handleSubmit(e)}
							className="space-y-8"
							action="#"
						>
							<div>
								<label
									htmlFor="email"
									className="block mb-2 text-sm font-medium  text-white"
								>
									E-Mail
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className=" border font-bold  rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
									placeholder="name@immobilienbesitzer.com"
									required
								></input>
							</div>
							<div>
								<label
									htmlFor="name"
									className="block mb-2 text-sm font-medium text-white"
								>
									Name
								</label>
								<input
									type="name"
									name="name"
									id="password"
									placeholder="Max Mustermann"
									className="border font-bold rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
									required
								></input>
							</div>
							<div>
								<label
									htmlFor="phone"
									className="block mb-2 text-sm font-medium text-white"
								>
									Telefonnummer
								</label>
								<input
									type="phone"
									name="phone"
									id="phone"
									placeholder="0171 234 567"
									className="border font-bold rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
									required
								></input>
							</div>
							<div>
								<button
									type="submit"
									className="w-full text-white mt-10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sky-600 hover:bg-sky-700 focus:ring-sky-800"
								>
									Exposé anfordern
								</button>
							</div>
						</form>
					</div>
				</div>
				{error && (
					<p className="text-red-500">
						{' '}
						Entschuldigung! Ein Fehler ist aufgetreten.
					</p>
				)}
			</div>
		</div>
	);
}
